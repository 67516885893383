<template>
  <div id="divLoginMainWrapper">
    <VueText sizeLevel="11" weightLevel="3" class="header" color="grey-40"> Giriş Yap</VueText>
    <VueListView dir="column" :isInline="false">
      <div class="phone-number-button-holder">
        <VueButton
          class="button"
          :size="sizes.xLarge"
          color="default"
          :outlined="true"
          as="router-link"
          :target="routes.login.WithPhoneNumber.path"
          :iconName="icons.mobile.name"
          :iconSize="{
            width: icons.mobile.width,
            height: icons.mobile.height,
          }"
          >Telefon Numaranız</VueButton
        >
      </div>
      <div class="button-holder">
        <VueButton
          class="button"
          :size="sizes.xLarge"
          color="default"
          :outlined="true"
          as="router-link"
          :target="routes.login.WithCustomerNumber.path"
          :iconName="icons.store.name"
          :iconSize="{
            width: icons.store.width,
            height: icons.store.height,
          }"
          >Müşteri Kodunuz</VueButton
        >
      </div>
      <div class="button-holder">
        <VueButton
          class="button"
          :size="sizes.xLarge"
          color="default"
          :outlined="true"
          as="router-link"
          :target="routes.login.WithTaxpayerIdNumber.path"
          :iconName="icons.envelop.name"
          :iconSize="{
            width: icons.envelop.width,
            height: icons.envelop.height,
          }"
          >VKN ile Giriş</VueButton
        >
      </div>
      <div
        style="display: flex; flex-wrap: wrap; justify-content: end;"
        class="first-login-button-holder"
      >
        <VueButton
          class="first-login-btn"
          color="white"
          as="router-link"
          :target="routes.login.FirstLogin.path"
          :contentAlignment="constants.flexAlignment.end"
          >İlk Giriş/Şifremi Unuttum</VueButton
        >
        <button id="ot-sdk-btn" class="ot-sdk-show-settings cookie-setting">Çerez Ayarları</button>
      </div>
    </VueListView>
    <BrandModal />
    <BrandAuthFormFooter></BrandAuthFormFooter>
  </div>
</template>

<script>
import VueButton from '@/components/shared/VueButton/VueButton.vue';
import VueListView from '@/components/shared/VueListView/VueListView.vue';
import VueText from '@/components/shared/VueText/VueText.vue';
import BrandAuthFormFooter from '@/components/brand/BrandAuthFormFooter/BrandAuthFormFooter.vue';
import COMPONENT_CONSTANTS from '@/constants/component.constants.js';
import { FLEX_JUSTIFIES } from '@/constants/flex.constants.js';
import RoutesLogin from '@/router/routes/authentication/RoutesLogin.js';
import BrandModal from '@/components/brand/Modals/BrandModal/BrandModal.vue';
import StorageHelper from '@/utils/storageHelper';

export default {
  name: 'LoginTypeSelection',
  components: { VueButton, VueListView, VueText, BrandAuthFormFooter, BrandModal },
  computed: {
    routes() {
      return {
        login: RoutesLogin,
      };
    },
    icons() {
      return COMPONENT_CONSTANTS.ICON_VARIABLES;
    },
    sizes() {
      return COMPONENT_CONSTANTS.COMPONENT_SIZES;
    },
    constants() {
      return {
        flexAlignment: FLEX_JUSTIFIES,
      };
    },
  },
  mounted() {
    let logoutInfo = new StorageHelper({ id: process.env.VUE_APP_STORAGE_KACC_LOGOUT_INFO }).get();

    if (logoutInfo) {
      setTimeout(() => {
        new StorageHelper({ id: process.env.VUE_APP_STORAGE_KACC_LOGOUT_INFO }).remove();
        let resultModalConfig = {
          isModalOpened: true,
          modalType: 'alert',
          noIcon: true,
          firstButtonText: 'TAMAM',
          modalText:
            'Şirkette çalışmadığınızı  belirttiğiniz için oturumunuz kapatıldı ve kullanıcınız silindi.',
        };
        this.$store.dispatch('app/setModalConfig', resultModalConfig);
      }, 100);
    }
  },
};
</script>

<style lang="scss">
.header {
  padding-top: palette-space-level('60');
}

.phone-number-button-holder {
  padding-top: palette-space-level('30');
}

.button-holder {
  padding-top: palette-space-level('40');
}

.button {
  min-width: 430px;
  padding: palette-space-level('30');
  font-weight: palette-font-weight-level('3');
}

.first-login-button-holder {
  padding: palette-space-level('15') 0 palette-space-level('45');
  .first-login-btn {
    text-decoration: underline;
    font-size: palette-font-size-level('6');
    padding-right: 0;
    font-weight: palette-font-weight-level('3');
    color: #23303d;
  }
}
.cookie-setting {
  padding-right: 0;
  font-size: 16px !important;
  background-color: white !important;
  border: 1px solid black !important;
  color: black !important;
}
</style>
