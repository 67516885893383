var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"divLoginMainWrapper"}},[_c('VueText',{staticClass:"header",attrs:{"sizeLevel":"11","weightLevel":"3","color":"grey-40"}},[_vm._v(" Giriş Yap")]),_c('VueListView',{attrs:{"dir":"column","isInline":false}},[_c('div',{staticClass:"phone-number-button-holder"},[_c('VueButton',{staticClass:"button",attrs:{"size":_vm.sizes.xLarge,"color":"default","outlined":true,"as":"router-link","target":_vm.routes.login.WithPhoneNumber.path,"iconName":_vm.icons.mobile.name,"iconSize":{
          width: _vm.icons.mobile.width,
          height: _vm.icons.mobile.height,
        }}},[_vm._v("Telefon Numaranız")])],1),_c('div',{staticClass:"button-holder"},[_c('VueButton',{staticClass:"button",attrs:{"size":_vm.sizes.xLarge,"color":"default","outlined":true,"as":"router-link","target":_vm.routes.login.WithCustomerNumber.path,"iconName":_vm.icons.store.name,"iconSize":{
          width: _vm.icons.store.width,
          height: _vm.icons.store.height,
        }}},[_vm._v("Müşteri Kodunuz")])],1),_c('div',{staticClass:"button-holder"},[_c('VueButton',{staticClass:"button",attrs:{"size":_vm.sizes.xLarge,"color":"default","outlined":true,"as":"router-link","target":_vm.routes.login.WithTaxpayerIdNumber.path,"iconName":_vm.icons.envelop.name,"iconSize":{
          width: _vm.icons.envelop.width,
          height: _vm.icons.envelop.height,
        }}},[_vm._v("VKN ile Giriş")])],1),_c('div',{staticClass:"first-login-button-holder",staticStyle:{"display":"flex","flex-wrap":"wrap","justify-content":"end"}},[_c('VueButton',{staticClass:"first-login-btn",attrs:{"color":"white","as":"router-link","target":_vm.routes.login.FirstLogin.path,"contentAlignment":_vm.constants.flexAlignment.end}},[_vm._v("İlk Giriş/Şifremi Unuttum")]),_c('button',{staticClass:"ot-sdk-show-settings cookie-setting",attrs:{"id":"ot-sdk-btn"}},[_vm._v("Çerez Ayarları")])],1)]),_c('BrandModal'),_c('BrandAuthFormFooter')],1)}
var staticRenderFns = []

export { render, staticRenderFns }